<template>
    <div>
        <AuthForm :title="$t('buttons.signUp')" :referral-user-id="referralUserId">
            <template #inputs>
                <CRInput
                    class="input"
                    v-model="email"
                    :label="$t('register.emailAddress')"
                    :placeholder="$t('placeholder.enterEmailAddress')"
                    :errors="errors?.email"
                    @onChange="$filters.clearError(errors, 'email')"
                    :help-text="$t('register.passwordWillBeSentToEmail')"
                />
                <p class="tooltip">
                    {{ $t('register.notice') }}
                    <router-link :to="{ name: 'privacyPolicy' }" target="_blank">
                        {{ $t('register.privacyPolicy') }}
                    </router-link>
                </p>
            </template>
            <template #buttons>
                <CRButton class="mr-10" @click="registerUser" :loading="isLoading">
                    {{ $t('buttons.signUp') }}
                </CRButton>
                <CRButton pattern="outline" @click="$router.push({ name: 'login' })">
                    {{ $t('buttons.logIn') }}
                </CRButton>
            </template>
        </AuthForm>

        <SuccessModal
            v-if="isReferralModalOpen"
            :is-success="false"
            :title="$t('referral.referralLinkRegistration')"
            :description="$t('referral.weAreSorryCanNotRegister')"
            @close="isReferralModalOpen = false"
        >
            <div class="btn__wrapper">
                <CRButton @click="isReferralModalOpen = false" class="mr-10" color="danger">
                    {{ $t('buttons.cancel') }}
                </CRButton>
                <CRButton @click="registerUser(false)" class="ml-10">
                    {{ $t('buttons.register') }}
                </CRButton>
            </div>
        </SuccessModal>
    </div>
</template>

<script>
    import AuthForm from '@/components/Forms/AuthForm.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { mapActions, mapGetters } from 'vuex';
    import SuccessModal from '@/components/Modals/SuccessModal.vue';
    import { ConversionEventNameEnum } from '@/utils/enums';

    export default {
        name: 'Register',
        components: { CRInput, CRButton, AuthForm, SuccessModal },
        data() {
            return {
                email: '',
                referralUserId: null,
                errors: {},
                isLoading: false,

                isReferralModalOpen: false,
            };
        },
        watch: {
            $route: {
                async handler(value) {
                    this.referralUserId = value.query?.referral_user_id ?? null;
                },
                immediate: true,
            },
        },
        computed: {
            ...mapGetters('auth', ['returnUrl'])
        },
        methods: {
            ...mapActions('auth', ['register']),

            async registerUser(withReferralUserId = true) {
                this.isLoading = true;
                this.isReferralModalOpen = false;

                const data = {
                    email: this.email,
                    referralUserId: withReferralUserId ? this.referralUserId : null,
                };

                try {
                    await this.register(data);

                    fbq('track', ConversionEventNameEnum.COMPLETE_REGISTRATION, {
                        content_name: 'simple_registration',
                    });
                    
                    if(this.returnUrl) {
                        this.$router.push(this.returnUrl);
                    } else {
                        this.$router.push('/');
                    }
                    
                } catch (error) {
                    this.errors = error.errors;

                    if (Object.entries(error.errors).length === 1 && error.errors.referralUserId.length) {
                        this.isReferralModalOpen = true;
                    }
                } finally {
                    this.isLoading = false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .input {
        margin-bottom: 20px;
    }

    .forgot-password-btn {
        margin-top: 3px;

        font-size: $font-12;
    }

    .tooltip {
        margin: 18px 0;

        font-style: italic;
        font-weight: 300;

        a {
            color: $primary;
            text-decoration: underline;
        }
    }

    .btn__wrapper {
        @include row-align-center;

        margin-top: 24px;
    }
</style>
